export const INITIALIZE_APPLICATION = "INITIALIZE_APPLICATION";
export const FETCH_AVAILABLE_LESSONS = "FETCH_AVAILABLE_LESSONS";
export const FETCH_AVAILABLE_LESSONS_SUCCEEDED =
  "FETCH_AVAILABLE_LESSONS_SUCCEEDED";
export const FETCH_AVAILABLE_LESSONS_FAILED = "FETCH_AVAILABLE_LESSONS_FAILED";
export const SET_INDEX = "SET_INDEX";
export const INCREMENT_INDEX = "INCREMENT_INDEX";
export const DECREMENT_INDEX = "DECREMENT_INDEX";
export const SET_OVERLAY_CONTENT = "SET_OVERLAY_CONTENT";
export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";
export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_SUCCEEDED = "FETCH_LESSON_SUCCEEDED";
export const FETCH_LESSON_FAILED = "FETCH_LESSON_FAILED";
export const FETCH_RESOURCE = "FETCH_RESOURCE";
export const FETCH_RESOURCE_SUCCEEDED = "FETCH_RESOURCE_SUCCEEDED";
export const FETCH_RESOURCE_FAILED = "FETCH_RESOURCE_FAILED";
export const SET_SLIDE_DEPENDENCIES = "SET_SLIDE_DEPENDENCIES";
export const SLIDE_RESOURCES_FAILED = "SLIDE_RESOURCES_FAILED";
